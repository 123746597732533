import React, {memo} from "react";
import ListSelect from "./Select";

type ListProps = {
    headers: string[]
    showHeaders: boolean
    inputs: {
        id: any
        elements: ElementType[]
    }[]
    updateEntry: (id: any, fieldName: string, fieldValue: any) => void
    saveItem: (id: any) => void
    deleteItem?: (id: any) => void
}

type ElementType = {
    type: "select" | "text"
    placeholder?: string
    fieldName: string
    fieldValue: any
    options?: { id: any, name: any }[]
}

const List = ({inputs, headers, updateEntry, saveItem, deleteItem, showHeaders = true}: ListProps) => {
    return (
        <table>
            <ListHeader headers={headers} showHeaders={showHeaders}/>
            <ListBody inputs={inputs} updateEntry={updateEntry} saveItem={saveItem} deleteItem={deleteItem}/>
        </table>
    )
}

const ListHeader = ({headers, showHeaders}: { headers: string[], showHeaders: boolean }) => {
    if (!showHeaders) return <thead/>;
    return (
        <thead>
        <tr>
            {headers.map((header, index) => <th key={index}>{header}</th>)}
        </tr>
        </thead>
    )
}

const ListBody = ({inputs, updateEntry, saveItem, deleteItem}: {
    inputs: { id: any, elements: ElementType[] }[],
    updateEntry: (id: any, fieldName: string, fieldValue: string) => void
    saveItem: (id: any) => void
    deleteItem?: (id: any) => void
}) => {
    if (inputs.length <= 0) return <tbody></tbody>;
    return (
        <tbody>
        {inputs.map((input, index) =>
            <MemoizedListItem key={input.id} id={input.id} elements={input.elements} updateEntry={updateEntry}
                              saveItem={saveItem} deleteItem={deleteItem}/>)}
        </tbody>
    )
}

const ListItem = ({id, elements, updateEntry, saveItem, deleteItem}: {
    id: any,
    elements: ElementType[],
    updateEntry: (id: any, fieldName: string, fieldValue: string) => void
    saveItem: (id: any) => void
    deleteItem?: (id: any) => void
}) => {
    return (
        <tr key={id}>
            {elements.map((element, index) => {
                let EType;
                if (element.type === "text") EType = <input
                    type="text"
                    placeholder={element.placeholder}
                    value={element.fieldValue || ''}
                    onChange={(e) => updateEntry(id, element.fieldName, e.target.value)}
                />;

                if (element.type === "select") {
                    if (!element.options) throw new Error("no options defined for select element");
                    EType = <ListSelect
                        value={element.fieldValue}
                        data={element.options}
                        placeholder={element.placeholder || ''}
                        onChange={(e) => updateEntry(id, element.fieldName, e.target.value)}
                    />;
                }

                if (!EType) throw new Error("no element type defined");

                return (
                    <td key={index}>
                        {EType}
                    </td>
                )
            })}
            <td>
                <button onClick={() => saveItem(id)}>Save</button>
            </td>
            {deleteItem ?
                <td>
                    <button onClick={() => deleteItem(id)}>Delete</button>
                </td>
                :
                <></>}
        </tr>
    )
}

const MemoizedListItem = memo(ListItem);

export default List;