const ListSelect = ({value, data, placeholder, onChange}: {
    value: string | number,
    data: { id: any, name: any }[],
    placeholder: string,
    onChange: React.ChangeEventHandler<HTMLSelectElement>
}) => {
    return <select
        value={value || ''}
        onChange={onChange}
    >
        <option>{placeholder}</option>
        {data && data.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
    </select>
}

export default ListSelect