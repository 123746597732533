import {initInternalApi} from "../newsletter-api/init";

let oldBrandId = 0;

export const fetchWebinars = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const {brandId, brands, templateData} = state.NewsletterReducer;

        let keyCount = 0;
        Object.keys(templateData.subTypes).forEach((key) => {
            if (key.toLowerCase().substring(0, 7) === 'webinar') keyCount = keyCount + 1;
        });

        let webinarCount = 3;
        if (keyCount > webinarCount) webinarCount = keyCount;

        // Keeps oldBranId on the heap to compare when dispatching preProcess actions
        // when any update is performed
        // this prevents duplicate re-renders & better yet, infinite re-renders...
        if (oldBrandId === brandId) return;
        oldBrandId = brandId;

        const brand = brands.find((brand) => brand.id === brandId);
        try {
            const api = initInternalApi();
            const webinars = await api.fetchWebinars(brand.pubCode);
            for (let i = 0; i < webinarCount; i++) {
                const webinarSubTypeKey = 'webinar' + (i + 1);

                dispatch({
                    type: 'EDITOR_INPUT_CHANGE', subTypeKey: webinarSubTypeKey, fieldTypeKey: 'list', value: webinars,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

let oldPodcastBrandId = 0;
export const fetchPodcasts = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const {brandId, brands} = state.NewsletterReducer;

        // Keeps oldBranId on the heap to compare when dispatching preProcess actions
        // when any update is performed
        // this prevents duplicate re-renders & better yet, infinite re-renders...
        if (oldPodcastBrandId === brandId) return
        oldPodcastBrandId = brandId;

        const brand = brands.find((brand) => brand.id === brandId);
        if (!brand || !brand.pubCode) return;
        try {
            const api = initInternalApi();
            const {group1, group2, group3, group4, group5} = await api.fetchPodcasts(brand.pubCode);
            dispatch({
                type: 'EDITOR_INPUT_CHANGE', subTypeKey: 'podcast1', fieldTypeKey: 'list', value: group1,
            })
            dispatch({
                type: 'EDITOR_INPUT_CHANGE', subTypeKey: 'podcast2', fieldTypeKey: 'list', value: group2,
            })
            dispatch({
                type: 'EDITOR_INPUT_CHANGE', subTypeKey: 'podcast3', fieldTypeKey: 'list', value: group3,
            })
            dispatch({
                type: 'EDITOR_INPUT_CHANGE', subTypeKey: 'podcast4', fieldTypeKey: 'list', value: group4,
            })
            dispatch({
                type: 'EDITOR_INPUT_CHANGE', subTypeKey: 'podcast5', fieldTypeKey: 'list', value: group5,
            })
        } catch (error) {
            console.log(error);
        }
    };
};
