import {initInternalApi} from "../newsletter-api/init";

const updateAllSponsors = (item) => {
    return (dispatch, getState) => {
        const [subType] = item.split('.');

        const {value: sponsor} =
            getState().NewsletterReducer.templateData.subTypes[subType].companyName;
        const {value: salesforceId} =
            getState().NewsletterReducer.templateData.subTypes[subType].salesforceId;
        dispatch({
            type: 'UPDATE_ALL_SPONSORS',
            sponsor,
            salesforceId,
        });
    };
};

const updateWebinar = (item, value) => {
    return (dispatch, getState) => {
        const [subType] = item.split('.');
        const {NewsletterReducer} = getState();
        const {value: webinars} =
            NewsletterReducer.templateData.subTypes.webinar1.list;
        const webinar = webinars.find((item) => item.title === value);
        let title = '';
        let registrationLink = '';
        if (webinar) {
            title = webinar.title;
            registrationLink = webinar.registrationLink;
        }
        dispatch({
            type: 'EDITOR_INPUT_CHANGE',
            subTypeKey: subType,
            fieldTypeKey: 'title',
            value: title,
        });
        dispatch({
            type: 'EDITOR_INPUT_CHANGE',
            subTypeKey: subType,
            fieldTypeKey: 'landPageUrl',
            value: registrationLink,
        });
    };
};

const updatePodcast = (item, value) => {
    return (dispatch, getState) => {
        const [subType] = item.split('.');
        const {NewsletterReducer} = getState();
        const {value: podcasts} =
            NewsletterReducer.templateData.subTypes[subType].list;
        const podcast = podcasts.find((item) => item.title === value);
        let title = '';
        let landPageUrl = '';
        let imageUrl = ''
        if (podcast) {
            title = podcast.title;
            landPageUrl = podcast.link;
            imageUrl = podcast.itunesImageUrl;
        }
        dispatch({
            type: 'EDITOR_INPUT_CHANGE',
            subTypeKey: subType,
            fieldTypeKey: 'title',
            value: title,
        });
        dispatch({
            type: 'EDITOR_INPUT_CHANGE',
            subTypeKey: subType,
            fieldTypeKey: 'landPageUrl',
            value: landPageUrl,
        });
        dispatch({
            type: 'EDITOR_INPUT_CHANGE',
            subTypeKey: subType,
            fieldTypeKey: 'podcastImageUrl',
            value: imageUrl,
        });
    };
};

const youTubeLink = (item) => {
    return async (dispatch, getState) => {
        const api = initInternalApi();
        try {
            const [subTypeKey] = item.split('.');
            const {subTypes} = getState().NewsletterReducer.templateData;
            const subType = subTypes[subTypeKey];
            const fieldType = subType['linkName'];
            const link = fieldType['value'];
            const response = await api.fetchByYouTubeLink(link);
            const {title, published, copy, logoImageUrl, landPageUrl} = response;
            dispatch({
                type: 'SUCCESS_FILL_FROM_YOUTUBE_LINK',
                subTypeKey,
                title,
                published,
                copy,
                logoImageUrl,
                landPageUrl,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_FILL_FROM_YOUTUBE_LINK',
                item: 'rootType.subType.fieldType', // TODO
                message: 'could not fill from youtube link',
            });
        }
    };
};

const fillFromLink = (item) => {
    return async (dispatch, getState) => {
        const api = initInternalApi();
        try {
            const [subTypeKey] = item.split('.');
            const {subTypes} = getState().NewsletterReducer.templateData;
            const subType = subTypes[subTypeKey];
            console.log(subType)
            const fieldType = subType['linkName'] !== '' && subType['linkName'] !== null && subType['linkName'] !== undefined ? subType['linkName'] : subType['landPageUrl'];
            const link = fieldType['value'];
            const response = await api.fetchPostByLink(link);
            const {title, featuredImage, excerpt, wordpressLink} = response;
            return dispatch({
                type: 'SUCCESS_FILL_FROM_LINK',
                subTypeKey,
                title,
                featuredImage,
                excerpt,
                wordpressLink,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_FILL_FROM_LINK',
                item: 'rootType.subType.fieldType', // TODO
                message: 'couldnt fill from link',
            });
        }
    };
};

const changeElDirection = (item) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_ELEMENT_DIRECTION',
            item,
        });
    };
};

export default {
    updateAllSponsors,
    fillFromLink,
    changeElDirection,
    youTubeLink,
    updateWebinar,
    updatePodcast
};
